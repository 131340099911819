// If you want to override variables do it here
@import "variables";

:root{
  --focus-color: #d91f26;
  --prime-color: #fff;
  --first-transition: all 0.3s linear;
  --slow-transition: all 0.4s linear;
  --regular-f-color: #000;
  --secondary-f-color: #949daf;
}

$enable-ltr: true;
$enable-rtl: false;

// Import CoreUI for React components library
@import "@coreui/coreui/scss/coreui";

// Import Chart.js custom tooltips styles
@import "@coreui/chartjs/scss/coreui-chartjs";

@import "layout";

// If you want to add custom CSS you can put it here.
@import "custom";

