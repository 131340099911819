// Here you can add other styles
.sidebar-brand {
  justify-content: center;
  padding: 10px 16px;
}


.app-data-grid {
  min-height: 300px;

  > header {
    padding: 0;
    box-shadow: none;
  }

  .rdt_TableHeadRow {
    background-color: var(--focus-color);
    color: #fff;
    font-size: 16px;
    font-weight: 700;
  }

  .rdt_TableCol {
    padding: 14px 20px;
  }

  .rdt_TableBody {
    .rdt_TableRow:nth-child(odd) {
      background-color: rgba(148, 157, 175, 15%);
    }

    .rdt_TableRow:nth-child(even) {
      background-color: rgba(148, 157, 175, 30%);
    }
  }

  .rdt_TableCell {
    padding: 5px 20px;
    color: #000;
    font-size: 16px;
    font-weight: 400;
  }

  .actions {
    display: flex;
    justify-content: space-around;
    align-content: center;
    flex-wrap: wrap;
    flex: 1;

    > button {
      padding: 10px;
      height: 40px;
      width: auto;
    }
  }
}

.no-inner-border {
  .no-left-border {
    border-left: none;
  }
  .no-right-border {
    border-right: none;
  }
}

.rotate {
  animation: rotation infinite 3s linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
